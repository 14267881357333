import api from "@/http";
import Storage from "@/utils/Storagehandle";
import { getProductCategoryList, getProductList } from "@/api/product";

const state = () => ({
  all: [],
  categoryList: [],
  productsCount: 0,
  productsDevCount: 0,
  productsOnCount: 0,
  productsOffCount: 0,
  productCache: Storage.getItem("productSessionStorageKey") || null, // 每次点击管理的时候缓存的产品数据
});

// getters
const getters = {
  categoryListSelect: (state) => {
    return [{ name: "全部", categoryId: "" }].concat(state.categoryList);
  },
};

// actions, action中可以异步获取数据
const actions = {
  loadProductSummary({ commit }, query) {
    api
      .post("/v1/product/list/page", query)
      .then((result) => {
        const { summary } = result;

        commit("setProductsCount", 0);
        commit("setDevCount", 0);
        commit("setOnCount", 0);
        commit("setOffCount", 0);

        let total = 0;
        let devCount = 0;
        for (let summaryItem of summary) {
          if (summaryItem.status === 0 || summaryItem.status === 3) {
            total += summaryItem.count;
            devCount = devCount + summaryItem.count;
            commit("setDevCount", devCount);
          } else if (summaryItem.status === 1) {
            total += summaryItem.count;
            commit("setOnCount", summaryItem.count);
          } else if (summaryItem.status === 2) {
            total += summaryItem.count;
            commit("setOffCount", summaryItem.count);
          }
        }
        commit("setProductsCount", total);
      })
      .catch(() => {});
  },

  loadProductCategoryList({ commit, state }) {
    const categoryList = state.categoryList;
    if (state.categoryList.length > 0) {
      return categoryList;
    } else {
      getProductCategoryList().then((result) => {
        commit("setProductsCategoryList", result);
      });
    }
  },

  productList({ commit }, query) {
    // const { pageNum, pageSize } = query;
    // const listPageUrl =
    //   "/v1/product/list/page?currPage=" + pageNum + "&pageSize=" + pageSize;

    // delete query.pageNum;
    // delete query.pageSize;

    // Object.keys(query).forEach((item) => {
    //   if (
    //     query[item] === null ||
    //     query[item] === undefined ||
    //     query[item] === ""
    //   ) {
    //     if (!query[item]) delete query[item];
    //   }
    // });

    getProductList(query)
      .then((result) => {
        const { list } = result;
        commit("setProducts", list);
        // commit("setProductsCount", total);
      })
      .catch(() => {});
  },
};

// mutations, 同步修改store中的数据
const mutations = {
  setProductsCategoryList(state, list) {
    state.categoryList = list;
  },
  setProducts(state, products) {
    state.all = products;
  },
  setProductsCount(state, count) {
    state.productsCount = count;
  },
  setDevCount(state, count) {
    state.productsDevCount = count;
  },
  setOnCount(state, count) {
    state.productsOnCount = count;
  },
  setOffCount(state, count) {
    state.productsOffCount = count;
  },
  setProductCache(state, product) {
    if (product === null) {
      Storage.removeItem(`productSessionStorageKey`);
    } else {
      Storage.setItem(`productSessionStorageKey`, product);
    }
    state.productCache = product;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
