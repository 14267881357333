// 遍历components下的组件，全局注册
import Vue from "vue";
const requireComponent = require.context(
  // 其组件目录的相对路径
  "./",
  // 是否查询其子目录
  true,
  // 匹配基础组件文件名的正则表达式
  /\.vue$/
);
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const name = componentConfig.default.name;
  Vue.component(name, componentConfig.default || componentConfig);
});
